<template>
  <v-card class="py-3 px-3 text-left checkout-summary" flat>
    <h3 class="grey--text text--darken-2">
      {{ $t("cart.checkoutSummary.cart") }}
    </h3>
    <h4 v-if="orderCart.shippingAddress.zone.zoneGroupId == 5">
      {{ $t("cart.checkoutSummary.orderNotEditable") }}
    </h4>
    <v-card-text>
      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-body-2"
      >
        <span>{{ $t("cart.checkoutSummary.itemsTotal") }}</span>
        <span>
          {{ $n(netTotal, "currency") }}
        </span>
      </v-row>
      <!-- totalPriceVariable -->
      <v-row
        v-if="orderCart.totalPriceVariable > 0"
        no-gutters
        class="d-flex justify-space-between mt-0 align-center text-body-2"
      >
        <span>{{ $t("cart.checkoutSummary.itemsTotalByWeight") }}</span>
        <span>{{ $n(orderCart.totalPriceVariable, "currency") }}</span>
      </v-row>
      <v-row
        v-if="orderCart.packageTotal && orderCart.packageTotal > 0"
        no-gutters
        class="d-flex justify-space-between align-center text-body-2"
      >
        <strong>{{ $t("cart.checkoutSummary.packageTotal") }}</strong>
        <div
          class="mt-2 d-flex justify-space-between align-top w-100"
          v-for="orderPackage in orderCart.orderPackages"
          :key="orderPackage.orderPackageId"
        >
          <span
            >{{ orderPackage.package.descr }} <br />
            <span class=""
              >({{ orderPackage.quantity }}x{{
                $n(orderPackage.package.cost, "currency")
              }})</span
            >
          </span>
          <span>{{ $n(orderPackage.grossTotal, "currency") }}</span>
        </div>
      </v-row>

      <div
        v-if="discount != 0"
        class="d-flex text-uppercase justify-space-between align-top font-weight-bold"
      >
        <span>{{ $t("cart.checkoutSummary.discount") }}</span>
        <span> -{{ $n(discount, "currency") }} </span>
      </div>

      <v-row
        v-if="deliveryFee > 0"
        no-gutters
        class="d-flex justify-space-between text-body-2"
      >
        <v-col class="delivery-fee-sub"
          ><span>{{ $t("cart.checkoutSummary.deliveryFee") }}</span>
          <p>{{ $t("cart.checkoutSummary.deliveryFeeSub") }}</p></v-col
        >
        <v-col class="d-flex flex-row justify-end">
          <span>{{ $n(deliveryFee, "currency") }}</span></v-col
        >
      </v-row>
      <v-row
        class=" d-flex justify-space-between align-top mt-0"
        v-if="
          orderCart.giftCertificatesToGenerate.length > 0 ||
            orderCart.giftCertificates.length > 0
        "
      >
        <v-col class="col col-12 pa-0 text-body-2">
          <strong>{{ $t("cart.checkoutSummary.gifts") }}</strong></v-col
        >
        <v-col
          class="col col-12 pa-0 d-flex flex-row justify-space-between"
          v-for="gift in orderCart.giftCertificatesToGenerate"
          :key="gift.giftCertificateId"
        >
          <span
            class="summary-textx px-2 white--text text-uppercase primary rounded-pill"
            >{{ gift.name }}</span
          >

          <span v-if="gift.total && gift.total > 0">
            -{{ $n(gift.total, "currency") }}</span
          >
          <span v-else> +{{ $n(replace(gift.total), "currency") }}</span>
        </v-col>
      </v-row>

      <!-- <v-row no-gutters class="d-flex justify-space-between align-top">
        <strong class="summary-text">{{
          $t("cart.checkoutSummary.packageTotal")
        }}</strong>
        <strong class="summary-value">{{
          $n(orderCart.packageTotal, "currency")
        }}</strong>
      </v-row> -->

      <!-- <v-row
        no-gutters
        class="d-flex justify-space-between align-top "
        v-for="orderPackage in orderCart.orderPackages"
        :key="orderPackage.orderPackageId"
      >
        <span class="summary-text"
          >{{ orderPackage.package.descr }} <br />
          ({{ orderPackage.quantity }}x{{
            $n(orderPackage.package.cost, "currency")
          }})
        </span>
        <span class="summary-value">{{
          $n(orderPackage.grossTotal, "currency")
        }}</span>
      </v-row> -->

      <v-row
        no-gutters
        v-for="gift in orderCart.giftCertificates"
        :key="gift.giftCertificateId"
        class="d-flex justify-space-between align-center"
      >
        <div>
          <v-btn icon @click="removeGiftCode(gift.userGiftCertificateId)" right>
            <v-icon x-small>$delete</v-icon>
          </v-btn>
          <span>{{ gift.name }}</span>
        </div>
        <span v-if="gift.total"> -{{ $n(gift.total, "currency") }}</span>
      </v-row>
      <!-- <v-row
        no-gutters
        class=" d-flex justify-space-between align-top"
        v-for="gift in orderCart.giftCertificatesToGenerate"
        :key="gift.giftCertificateId"
      >
        <span
          class="summary-text px-2 white--text text-uppercase primary rounded-pill"
          >{{ gift.name }}</span
        >
        <span class="summary-value" v-if="gift.giftTotal">
          {{ $n(gift.giftTotal, "currency") }}</span
        >
      </v-row> -->

      <v-row
        no-gutters
        class="total d-flex justify-space-between align-center text-uppercase"
      >
        <span>{{ $t("cart.checkoutSummary.total") }}</span>
        {{ $n(orderGrossTotal, "currency") }}
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.summary-cards .checkout-summary .summary-textx {
  color: white !important;
}
.delivery-fee-sub {
  p {
    font-size: 9px;
    margin-bottom: 0px;
  }
}
</style>
<script>
export default {
  name: "CheckoutSummary",
  props: { orderCart: { type: Object, required: true } },
  computed: {
    netTotal() {
      if (
        this.orderCart.orderId &&
        this.orderCart.orderStatusId >= 8 &&
        this.orderCart.orderAdjustmentSummary
      ) {
        var refundTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_REFUND_TOTAL.totalVariation;
        var discountItemTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_ITEM_TOTAL.totalVariation;
        var discountNetTotalDel = this.orderCart.orderAdjustmentSummary
          .TYPE_NET_TOTAL.totalVariation;
        return (
          this.orderCart.grossTotal +
          refundTotalDel +
          discountItemTotalDel +
          discountNetTotalDel
        );
      }
      console.log(this.orderCart);
      var netTotal = this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
      if (this.orderCart.orderAdjustmentSummary) {
        var discountItemTotal = this.orderCart.orderAdjustmentSummary
          .TYPE_ITEM_TOTAL.totalVariation;
        netTotal += discountItemTotal;
      }

      return netTotal;
    },
    discount() {
      var discountItemTotal = 0;
      if (this.orderCart.orderAdjustmentSummary) {
        discountItemTotal = this.orderCart.orderAdjustmentSummary
          .TYPE_ITEM_TOTAL_PROMO.totalVariation;
      }
      return discountItemTotal;
      // return this.orderCart.giftTotal + this.orderCart.totalDiscount;
    },
    orderGrossTotal() {
      //  return this.orderCart.giftTotal + this.orderCart.totalDiscount;
      var refundTotal = 0;
      if (this.orderCart.orderAdjustmentSummary) {
        refundTotal = this.orderCart.orderAdjustmentSummary.TYPE_REFUND_TOTAL
          .totalVariation;
      }

      return this.orderCart.grossTotal + refundTotal;
    },
    deliveryFee() {
      const totalVariation = this.orderCart.orderAdjustmentSummary
        ? this.orderCart.orderAdjustmentSummary.TYPE_SHIPPING_TOTAL
            .totalVariation
        : null;
      return this.orderCart.deliveryFee + totalVariation;
    }
  },
  methods: {
    removeGiftCode(giftCodeId) {
      this.$emit("removeGiftCode", giftCodeId);
    },
    replace(giftTotal) {
      return giftTotal.toString().replace("-", "");
    }
  }
};
</script>
